.page-content {
    @media screen and (min-width: 500px) {
        padding: 0 20px;
    }
}

.card {
    @media screen and (min-width: 500px) {
        max-width: fit-content;
        margin: 0 auto 20px;
    }

    margin: 0 0 20px;
}

.data-grid-card {
    width: 100%;
}

.data-grid-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#btn-reimbursements-register {
    @media screen and (min-width: 500px) {
        width: 250px
    }
}

.data-grid-footer {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto;
    column-gap: 5px;
}

.card-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-actions {
    display: flex;
    flex-direction: row;
}

.form {
    @media screen and (min-width: 500px) {
        min-width: 384px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px 10px;
    }
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}

$user_form_fields: "name", "email", "cpf", "phone", "bank_name", "bank_account", "bank_agency", "pix_key";
$company_form_fields: "name", "cnpj", "phone", "street", "complement", "web_site", "city", "state", "zip_code", "country", "zipcode", "website";
$register_form_fields: "terms", "action", "password", "password_confirmation";
$operations_form_fields: "or_name", "client_name";
$reimbursements_form_fields: "type", "value_required", "invoice_dt", "description", "operation_id", "file";
$form_fields: "forgot_password", "old_password", "new_password", "new_password_confirmation", "is_admin";
$card_form_fields: "card_number", "exp_month", "exp_year", "cvv", "holder_document", "holder_name", "zip_code", "address_number", "street", "complement", "neighborhood", "city", "state", "country";
$form_style: $form_fields, $user_form_fields, $company_form_fields, $register_form_fields, $operations_form_fields, $reimbursements_form_fields, $card_form_fields;

@each $form in $form_style {
    @each $field in $form {
        .input-#{$field} {
            grid-area: #{$field};
            width: 100%;
        }
    }
}

.form-login {
    @media screen and (min-width: 500px) {
        min-width: 300px;
        grid-template-areas:
            "email email"
            "password password"
            "terms terms"
            "forgot_password forgot_password"
            "action action"
        ;
    }
}

.input-terms {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.form-forgot_password {
    @media screen and (min-width: 500px) {
        min-width: 300px;
    }
    justify-content: center;
    grid-template-columns: auto;
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
    --ReactInputVerificationCode-itemSpacing: 0.5rem;
}

.plan-info {
    @media screen and (min-width: 600px) {
        width: 100vh;
    }
}

.form-user {
    @media screen and (min-width: 600px) {
        width: 100vh;
    }
    grid-template-areas:
        "name name"
        "email email"
        "cpf phone"
        "bank_name bank_name"
        "bank_account bank_agency"
        "pix_key pix_key"
        "is_admin is_admin"
    ;
}

.form-user-dialog {
    grid-template-areas:
        "name name"
        "email email"
        "cpf phone"
        "bank_name bank_name"
        "bank_account bank_agency"
        "pix_key pix_key"
        "is_admin is_admin"
    ;
}

.form-company {
    @media screen and (min-width: 600px) {
        width: 100vh;
    }
    grid-template-areas:
        "name name"
        "cnpj phone"
        "street street"
        "complement complement"
        "city state"
        "country zipcode"
        "bank_name bank_name"
        "bank_account bank_agency"
        "pix_key pix_key"
        "website website"
    ;
}

.form-clients {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "name name"
            "document_number type"
            "email initials"
        ;
    }
}

.form-operation {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "client_name client_name"
            "or_name or_name"
        ;
    }
}

.form-reimbursement {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "type value_required"
            "operation_id operation_id"
            "file invoice_dt"
            "description description"
        ;
    }
}

.form-sign-plan {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "company_name contact_mode"
            "person_name phone"
            "email email"
        ;
    }
}

.form-operation-update {
    justify-items: center;
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "or_name or_name"
            "client_name client_name"
        ;
    }
}

.form-card-update {
    justify-items: center;
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "card_number card_number cvv"
            "exp_month holder_document holder_document" 
            "exp_year holder_name holder_name"
            "zip_code zip_code country"
            "city city state"
            "street street address_number"
            "complement complement neighborhood"
        ;
    }
}

.form-clients-update {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "name name"
            "document_number type"
            "email initials"
        ;
    }
}

.form-invoice-update {
    justify-items: center;
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "or_name or_name"
            "client_name client_cnpj"
        ;
    }
}

.form-reimbursement-update {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "type value_required"
            "file invoice_dt"
            "description description"
        ;
    }
}

.form-reneg-update {
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "status status"
        ;
    }
}

.form-register {
    width: 130vh;
    grid-template-areas:
        "password password"
        "password_confirmation password_confirmation"
        "terms terms"
        "action action"
    ;
}

.form-password {
    width: 130vh;
    grid-template-areas:
        "password password"
        "password_confirmation password_confirmation"
    ;
}

.form-password-dialog {
    grid-template-areas:
        "password password"
        "password_confirmation password_confirmation"
    ;
}

.form-change_password {
    @media screen and (min-width: 600px) {
        width: 100vh;
    }
    @media screen and (min-width: 500px) {
        grid-template-areas:
            "old_password old_password"
            "new_password new_password_confirmation"
        ;
    }
}

.logo{
    display: block;
    margin-right: auto;
}

.hr-home {
    margin: auto;
    width: 50%;
    color: "#48c0ff";
  }